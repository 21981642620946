.about-sec {
    width: 100%;
    padding: 4rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f4
}

.about-container {
  display: flex;
  max-width: 1200px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.about-txt {
  flex: 1;
  max-width: 600px;
  padding-top: 4rem;
}

.about-h1 {
  font-size: 3.2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #000;
}

.about-p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: #555;
}

.about-btn {
  display: flex;
  gap: 1rem;
}

.btn-m {
  padding: 1rem;           
  border: none;
  border-radius: 7px;      
  font-size: 1rem;
  font-weight: bold;       
  height: 4.5rem;            
  width: 13rem;  
  cursor: pointer;
  text-align: auto;      
  display: flex;  
  box-sizing: border-box;         
} 

.btn-primary {
  background-color: #000;
  color: #fff;
  display: inline-block;         
  text-decoration: none;
} 

.btn-secondary {
  color: #555;
  border: 1px solid #000;
  background-color: #eff3f7;
 
}

.anch {
    display: inline-block;         
    text-decoration: none;
    color: #555;
}

.about-img-sec {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 4rem;
    height: 530px;
    
}

.about-img {
    width: 420px;
    height: 420px;
    border-radius: 33%;
    object-fit: cover;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border: 2px;
}

.essay-sec {
  flex: 1;
  margin: 4rem 1rem;  
}

.essay-h3 {
  font-weight:bold; 
  font-size: 36px; 
  margin-bottom: 1rem
}




@media (max-width: 768px) {
    .about-container {
      flex-direction: column;
      text-align: center;
    }
  
    .about-text {
      margin-bottom: 2rem;
    }
  
    .about-image {
      width: 300px;
      height: 300px;
    }
  }