.proj-sec {
    position: relative;
    height: 50hv;
    width: 100vw;  
}

.img-sec {
    width: 100%;
    height: 78vh;
   object-fit: cover;
}


.overlay-text {
    position: absolute;  
    top: 50%;  
    left: 50%;  
    transform: translate(-50%, -50%); /* Center text exactly */ 
    text-align: center;  
    color: white; 
    font-family: Arial, sans-serif;  
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6); /* Adds readable shadow */ 
}


.projects-section {
  display: flex;
  justify-content: center;
  padding: 3rem 1rem;
}

.projects-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 cards per row */
  gap: 4rem; /* Space between cards */
  max-width: 1200px; /* Limit total width */
  width: 110%;
}

.project-card {
  display: block;
  background-color: #f0f0f5 ;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: transform 0.3s ease;
  text-align: left;
  width: 112%;

  .semi-card {
    display: block;
    background-color: #e2e2ed;
    height: 61%;
    width: 91%;
    justify-self: center;
    border-radius: 12px;
  }
}



.mockup-container {
  display: block;
  width: 90%;
  aspect-ratio: 3 / 4; /* Ensure consistent aspect ratio for images */ 
  overflow: hidden;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 2rem;
  background-color: #666;
  justify-content: center;
  margin-left: 1.2rem;


  .mockup-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.shape {
  height: auto;
  border: 15px solid #000;
  align-self: auto;
  margin: 1.5rem 3rem 1rem 1.1rem;
  border-radius: 10px;
}


.project-info {
  padding-left: 1.5rem ;
}

.project-title {
  font-size: 1.2rem;
  color: #333;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.project-description {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 1rem;
}
.tags-container {
  display: flex; 
  flex-wrap: wrap; 
  gap: 8px; 
  margin-top: 0.5rem; 
}

.tag {
  background-color: #000; 
  color: white; 
  padding: 0.3rem 0.6rem; 
  border-radius: 12px; 
  font-size: 0.85rem; 
  white-space: nowrap; 
  display: inline-block; 
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); 
}


.project-demo {
  display: inline-block;
  margin-top: 1rem;
  font-size: 0.85rem;
  font-weight: bold;
  color: #000;
  text-decoration: none;
  cursor: pointer;
  padding: 1rem 0rem 5rem;
}

.ready {
  background-color: #f0f0f5;
  height: 18rem;
  width: 100%;
  justify-items: center;
  margin-bottom: 80px;
}

.ready-h1 {
  padding-top: 4rem;
  font-weight: bold;
}

.text {
  font-size: large ;
  font-weight: 400;
  padding-top: 0.7rem;
  align-self: center;
}

.ready-button {
  background-color: #0073e6;
  color: #fff;
  width: 99px;
  height: 40px;
  border: none;
  justify-items: center;
  border-radius: 5px;
  font-weight: bold;
  margin-right: 2.5rem;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .projects-container {
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
  }
}

@media (max-width: 768px) {
  .projects-container {
    grid-template-columns: repeat(1, 1fr); /* 1 card per row */
  }
}













