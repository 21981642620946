.navbar {
    position: sticky;
    background-color: #fdfdfd;
    border-bottom: 1px solid #f0f0f5;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    top: 0;
    left:0;
    height: 4.5rem; 
    width: 100%; 
    z-index: 1000; 
}

.pagestext {
    color: rgb(244, 239, 239)
    
} 

.container-fluid {
    width: 100%;
    max-width: 100%; /* Ensure no restrictions */
    margin: 0;
    padding: 0;
  }
  
  .let-talk {
    transform: translate(-15%);
    font-size: 16px; 
    text-align: center;
    
  }


@media (min-width: 1024px) {
  
}

  @media (max-width: 768px) {
    .navbar {
      position: sticky;
      background-color: #fdfdfd;
      border-bottom: 1px solid #f0f0f5;
      height: 4rem; /* Adjust height for mobile */
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      top: 0;
      left:0;
      width: 100%; 
      z-index: 1000; 
    }
  
    .pagestext {
      color: rgb(244, 239, 239); /* Keep same color */
    }
  
    .container-fluid {
      max-width: 100%; /* Ensure full width */
      width: 100%;
      padding: 0 0.5rem; /* Add padding for better spacing */
      margin: 0;
      padding: 0;
    }
  
    .let-talk {
      transform: translate(-80%);
      font-size: 16px; /* Adjust font size */
      text-align: center; /* Center-align text for smaller screens */
    }
  }
  
  