/* General Styles */

* {
  overflow: hidden;
  overflow-y: scroll;
}


.home-section {
   background-color: #eff3f7;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* a subtle shadow for depth */ 
   text-align: center;
   padding: 0rem 1.5rem

}

.home-section h1 {
    text-align: center;
    padding: 6rem 2rem;
    width: 100%;
    display: inline-block;
}

.home-section p {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto 2rem auto;
}


/* Line Styles */
.line {
    display: block;
    font-weight: 700;
    align-items: center;
}

.line.long {
    font-size: 3rem; /* Larger font for the first line */
    text-transform: uppercase;
    letter-spacing: 0.05em; /* Slight spacing for effect */
    margin: 0 auto; /* Center-aligns */
    margin-top: 1.5rem;
}

.line.short {
    font-size: 3rem; /* Slightly smaller font for the second line */
    margin-top: 0.5rem; 
    margin-left: 2rem ;
    margin-right: 3rem;
}


.home-button {
    display: block;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
}

.call-input {
    padding: 0.9rem 1.3rem;
    border: 1px solid #ddd;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    width: 48%;
    height: 3.5rem;
    font-size: 1rem;
    margin-right: 1rem;
    color: #555;
}

.button {
    padding: 1rem 1.1rem;
    background-color: #0073e6;
    color: #fff;
    width: auto;
    max-width: 150px;
    border: none;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    height: 3.5rem;
    font-weight: bold;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #005bb5;
  }


  /* Style the modal background */
 .modal {
    display: none; /* Hidden by default */
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Transparent dark background */
    overflow: auto;
    align-items: center;
    justify-content: center;
  }
  
  /* Style the modal content */
  .modal-content {
    position: relative;
    margin: 10% auto;
    padding: 1rem;
    background: #fff; /* Calendar background */
    width: 90%;
    max-width: 600px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Close button styling */
  .close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .open-modal-btn {
    padding: 0.6rem 1.2rem;
    font-size: 1rem;
    background-color: #0073e6;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .open-modal-btn:hover {
    background-color: #005bb5;
  }

  .img-container {
    width: 100%;                /* Full width of the container */
    height: 300px;              /* Set the height to control how much of the image is shown */
    overflow: hidden;           /* Hide anything outside the container */
    border-top-left-radius: 0px; /* Adjust the radius as needed */
    border-top-right-radius: 0px;
    margin-top: 2rem;
  }
  
  .img {
    width: 57%;
    height: 630px;
    border-radius: 14px;
    object-fit: cover;
   
  }

  .section-behere {
    align-items: center;
    justify-content: center;
    margin: 2.5rem 30rem;
    font-size: large;
    font-family: Arial, sans-serif;
    font-weight: 700;
    color: #726767
  }

  .section-create {
    align-items: center;
    justify-content: center;
    margin: 0.5rem 14.5rem;
    font-size: xx-large;
    display: none;
    font-family: Arial, sans-serif;
    font-weight: 900;
  }


.statistics-section h2 {
    font-size: 24px;
    font-weight: 700;
    color: #1a1a1a; /* Dark text color */
    margin-bottom: 30px;
  }
  
  /* Stats card container */
  .stats-container {
    display: flex;
    justify-content: space-around;
    background-color: #eff3f7; 
    border-radius: 5px;        
    padding: 25px;
    box-shadow: 0 2px 0px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    width: 87%;
    margin-left: 4rem;
    align-content: center;
  }
  
  /* Individual stat items */
  .stat-item {
    text-align: center;
    flex: 1;
  }
  
  .stat-value {
    font-size: 28px;
    font-weight: 750;
    color: #1a1a1a; /* Dark text color */
    display: block;
  }
  
  .stat-label {
    font-size: 18px;
    color: #6c757d; /* Subtle grey color */
    margin-top: 5px;
  }

  .elev-container{
    padding-top: 95px;
    display: flex;
    justify-content: center;
  }

  .section-elev {
    color:  #726767;
    font-weight: bold;
    font-size: 220;
  }

  .let-container {
    margin: 0.7rem 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .let-section {
    font-weight: 530;
    font-size: xx-large;
    font-family: Arial, sans-serif;

  }

  .attributes-section {
     display: grid;
     grid-template-columns: repeat(3, 1fr); /* 3 cards per row */
     margin: 6rem 0rem 1.5rem 4rem;
  }

  .attribute-header {
    justify-content: center;
    margin: 0rem 0rem 1rem 1rem;
    font-weight: bold;
    font-size: 17px;
    text-align: center;
  }

  .attribute-items {
    margin: 0rem 2rem 4rem -2rem;
    text-align: center;
  }

  .span-txt{
    color: #444445;
  }

  .fi {
    width: 20px;
    height: 22px;
    fill: #000;
    margin: 0rem 0rem 0.7rem 1.5rem;
    justify-content: center;
}

.img2-container {
  position: relative;
  display: flex;
  gap: 2rem; 
  justify-content: center; 
  align-items: center; 
  margin: 0 auto; 
  width: 100%; 
  height: 36rem; 
  background-color: #eff3f7;
  padding: 1rem; 
  box-sizing: border-box; 
}

.img-container2 {
  flex: 1; 
  height: 75%; 
  max-width: 330px; 
  display: flex; 
  justify-content: center;
  align-items: center;
  margin: 11rem 0rem 7rem 0rem;
  padding-bottom: 4.5rem;
  
}

.img2-items {
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
  border-radius: 15px; 
  border: #696767 solid 1px ;

}

.img2-txt {
  position: absolute; /* Positions the text on top of images */
  top: 1rem; /* Distance from the top */
  left: 50%; /* Center the text horizontally */
  transform: translateX(-50%); /* Align text to center */
  font-size: 0.9rem;
  font-weight: bold;
  color: #706e6e; /* Text color */
  z-index: 10; /* Ensures the text appears above the images */
  text-align: center;
  padding: 1rem 1rem; /* Padding for better readability */
}

.h3-txt {
  position: absolute; 
  top: 2.5rem; 
  left: 50%; 
  transform: translateX(-50%); 
  font-size: 2rem;
  font-weight: bold;
  color: #121212; 
  z-index: 12; 
  text-align: center;
  padding: 1rem 0.5rem ; 
}

.img-caption {
   position: absolute;
   top: 28rem;
   padding-top: 1rem;
}
.caption-h{
  font-size: large;
  font-weight: bold;
}
.img-pp {
   color: #706e6e;
   font-size: medium;
   justify-self: center;
}


/* Media Responsive for Ipad*/
@media (max-width: 1024){
  .section-behere {
    align-items: center;
    justify-content: center;
    margin: 2.5rem 16rem;
    font-size: large;
    font-family: Arial, sans-serif;
    font-weight: 700;
    color: #726767
  }
  
}
/* Responsive Adjustments */
/* Styles for mobile screens */
@media (max-width: 768px) {
  
  .home-section {
    background-color: #eff3f7;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    text-align: auto;
    padding: 0rem 1.1rem
 
 }
  .home-section h1 {
    text-align: center;
    padding: 5rem 1rem 0.5rem;
    width: 100%;
    display: inline-block;
  }

  .home-section p {
    font-size: 1.1rem;
    color: #555;
    line-height: 1.6;
    max-width: 600px;
    margin: 0 auto 2rem auto;
  }

  .line {
    display: block;
    font-weight: 700;
    align-items: center;
}

.line.long {
    font-size: 2rem; /* Larger font for the first line */
    text-transform: uppercase;
    letter-spacing: 0.05em; /* Slight spacing for effect */
    margin: 0 auto; /* Center-aligns */
    margin-top: 1.5rem;
}

.line.short {
    font-size: 2rem; /* Slightly smaller font for the second line */
    margin-top: 0.5rem; 
    margin-left: 2rem ;
    margin-right: 2rem;
}



.home-button {
  display: block;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
}

.call-input {
  padding: 0.9rem 1.3rem;
  border: 1px solid #ddd;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  width: 70%;
  height: 3.5rem;
  font-size: 1rem;
  margin-right: 1rem;
  color: #555;
}

.button {
  padding: 1.2rem 0rem;
  background-color: #0073e6;
  color: #fff;
  border: none;  height: 3.5rem;
  max-width: 50px;
  width: 10%;
  font-weight: bold;
  text-decoration: none;
  font-size: 0.8rem;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #005bb5;
}


/* Style the modal background */
.modal {
  display: none; /* Hidden by default */
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Transparent dark background */
  overflow: auto;
  align-items: center;
  justify-content: center;
}

/* Style the modal content */
.modal-content {
  position: relative;
  margin: 10% auto;
  padding: 1rem;
  background: #fff; /* Calendar background */
  width: 90%;
  max-width: 600px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Close button styling */
.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 1.5rem;
  cursor: pointer;
}

.open-modal-btn {
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  background-color: #0073e6;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.open-modal-btn:hover {
  background-color: #005bb5;
}

.img-container {
  width: 100%;                /* Full width of the container */
  height: 300px;              /* Set the height to control how much of the image is shown */
  overflow: hidden;           /* Hide anything outside the container */
  border-top-left-radius: 0px; /* Adjust the radius as needed */
  border-top-right-radius: 0px;
  margin-top: 2rem;
}

.img {
  width: 57%;
  height: 630px;
  border-radius: 14px;
  object-fit: cover;
 
}

.section-behere {
  align-items: center;
  justify-content: center;
  margin: 2rem 6rem;
  font-size: large;
  font-family: Arial, sans-serif;
  font-weight: 700;
  color: #726767
}

.section-create {
  align-items: center;
  justify-content: center;
  margin: 0.5rem 3rem;
  display: inline-block;
  font-size: xx-large;
  font-family: Arial, sans-serif;
  font-weight: 700;
}


.statistics-section h2 {
  font-size: 24px;
  font-weight: 700;
  color: #1a1a1a; /* Dark text color */
  margin-bottom: 30px;
}

/* Stats card container */
.stats-container {
  display: flex;
  justify-content: space-around;
  background-color: #eff3f7; 
  border-radius: 5px;        
  padding: 25px;
  box-shadow: 0 2px 0px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  width: 100%;
  margin-left: 0rem;
  align-content: center;
}

/* Individual stat items */
.stat-item {
  text-align: center;
  flex: 1;
}

.stat-value {
  font-size: 25px;
  font-weight: 700;
  color: #1a1a1a; /* Dark text color */
  display: block;
}

.stat-label {
  font-size: 16px;
  color: #6c757d; /* Subtle grey color */
  margin-top: 4px;
}

.elev-container{
  padding-top: 95px;
  display: flex;
  justify-content: center;
}

.section-elev {
  color:  #726767;
  font-weight: bold;
  font-size: 220;
}

.let-container {
  margin: 0.7rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.let-section {
  font-weight: 530;
  font-size: 26px;
  font-family: Arial, sans-serif;

}

.attributes-section {
   display: grid;
   grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
   margin: 4rem 0.4rem 1.5rem 3rem;
   padding-left: 0.3rem;
}

.attribute-header {
  justify-content: center;
  margin: 0rem 0rem 0.5rem 1rem;
  font-weight: bold;
  font-size: 17px;
  text-align: center;
}

.attribute-items {
  margin: 0rem 2rem 2rem -2rem;
  text-align: center;
  justify-content: center;
}

.span-txt{
  color: #444445;
}

.fi {
  width: 20px;
  height: 22px;
  fill: #000;
  margin: 0rem 0rem 0.7rem 1.5rem;
}

.img2-container {
position: normal;
gap: 1rem; 
justify-content: center; 
align-items: center; 
margin: 0 auto; 
width: 100%; 
height: 38rem; 
background-color: #eff3f7;
padding: 1rem; 
box-sizing: border-box; 
}

.img-container2 { 
flex: 1; 
height: 75%; 
max-width: 330px; 
display: flex; 
justify-content: center;
align-items: center;
margin: 11rem 0rem 7rem 0rem;
padding-bottom: 4.5rem;

}

.img2-items {
width: 100%; 
height: 80%; 
object-fit: cover; 
border-radius: 15px; 
border: #696767 solid 1px ;

}

.img2-txt {
position: absolute; /* Positions the text on top of images */
top: 0.3rem; /* Distance from the top */
left: 50%; /* Center the text horizontally */
transform: translateX(-50%); /* Align text to center */
font-size: 0.9rem;
font-weight: bold;
color: #706e6e; /* Text color */
z-index: 10; /* Ensures the text appears above the images */
text-align: center;
padding: 0.1rem 0rem; /* Padding for better readability */
}

.h3-txt {
position: absolute; 
top: 3.5rem; 
left: 50%; 
transform: translateX(-50%); 
font-size: 1.4rem;
font-weight: bold;
color: #121212; 
z-index: 10; 
text-align: center;
padding: 0.8rem ; 
}

.img-caption {
 top: 28rem;
 padding-top: 1rem;
 text-align: center;
 min-height: 2rem;
}
.item {
  flex: 1 1 30%; /* Adjust width to prevent overlap */
  min-width: 250px; /* Ensures responsiveness */
  padding: 10px;
  text-align: center;
}
.caption-h{
font-size: large;
font-weight: bold;

}
.img-pp {
 color: #706e6e;
 font-size: medium;
 justify-self: center;
}
}